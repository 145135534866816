@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-flex-video;
@include foundation-label;
//@include foundation-media-object;
//@include foundation-menu;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;

//@include motion-ui-transitions;
//@include motion-ui-animations;

@mixin sfondo_con_gradiente_h {
  background: $ricerca-color; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, $ricerca-color, $studi-color, $tutorial-color); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, $ricerca-color, $studi-color, $tutorial-color); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, $ricerca-color, $studi-color, $tutorial-color); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, $ricerca-color, $studi-color, $tutorial-color); /* Standard syntax */
}
@mixin sfondo_con_gradiente_v {
  background: $ricerca-color; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(top, $ricerca-color, $studi-color, $tutorial-color); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom, $ricerca-color, $studi-color, $tutorial-color); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom, $ricerca-color, $studi-color, $tutorial-color); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom, $ricerca-color, $studi-color, $tutorial-color); /* Standard syntax */
}

/* open-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v40-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/open-sans-v40-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v40-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/open-sans-v40-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v40-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/open-sans-v40-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

html, body {
  height: 100%;
}

input { box-shadow: none !important; }

dt {
  color: $white;
}

video {
  margin-bottom: 15px;
}

.top {
  z-index: 999;
	position: fixed;
  width: 100%;
  max-width: 100%;
  min-height: 50px;
  @include sfondo_con_gradiente_h;
  a { color: rgba(255, 255, 255, 0.7); }
  a:hover { color: white; }
  .merck {
    padding-top: 10px;
  }
  .links {
    font-size: 14px;
    /*font-weight: bold;*/
    padding-top: 0px;
    text-transform: uppercase;
  }
  .utili { margin-right: -5.5rem; }

  .brand {
    visibility: hidden;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    padding-top: 14px;
    margin-left: 10rem;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.7);
  }

}
.benvenuto {
  border-left :8px solid #f25800;
  padding-left: 20px;
  margin-bottom: 20px;
}
.privacy {
  display: none;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  padding-top: 5px;
  .button { margin-top: 5px;}
}
.page-wrap {
  min-height: 100%;
  margin-bottom: -70px; /* equal to footer height */
}
.page-wrap:after {
  content: "";
  display: block;
}
.footer, .page-wrap:after {
	height: 70px;
}

.subtop {
  top: 50px;
  position: fixed;
  color: $white;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  @include sfondo_con_gradiente_h;
  border-top: 10px solid white;
  .testo {
    font-size: 1.4rem;
    padding-left: 100px;
    padding-right: 90px;
  }
}

#the_message { display: none;}

#video { text-align: center; margin-bottom: 20px; }

.fa { font-size: 4em; }

.btn-100 { width: 100%; }
/*.button { width: 100%; }*/
.button.hollow { color: $black; }
.callout { padding-bottom: 0.2rem; }

.accordion-title { font-size: 1rem; line-height: 1.3rem; }
.nota-ricerca { font-size: 0.8rem; }

.loading {
  text-align: center;
}

.no-pad-left {
  padding-left: 0px !important;
}
.no-pad-right {
  padding-right: 0px !important;
}

.clear-filter {
  float: right;
  position: relative;
  top: -57px;
  right: 7px;
  height: 0px;
  font-size: 1.3rem;
  font-weight: bold;
  a { color: $dark-gray !important; }
}

.video {
	margin-top: 20px;
	margin-bottom: 10px;
	thead, tbody, tfoot { border: 0; }
	tbody tr { background-color: $white; }
	.freccia { font-family: Times; font-size: 5em; }
}

.recaptcha, .g-recaptcha {
  margin-top: 15px;
	margin-bottom: 15px;
}

.space-header { height: 150px; }
.space-header-home { height: 250px; }

.help {
  font-size: 0.9rem;
  .intro { margin-bottom: 10px; }
  .outro { margin-top: 10px; }
}

.link-utili {
  .filetto { background-color: $light-gray; height: 1px; margin-bottom: 10px; float: none; }
}

.page-header {
  top: 50px;
  position: fixed;
  z-index: 1;
	width: 100%;
  color: $white;
  text-align: center;
  border-top: 10px solid white;

  .ph-glossario {
    border-bottom: 30px solid white;
  }

	.ricerca {
    padding-top: 8px;
    padding-bottom: 2px;
		background-color: $ricerca-color;
	}
	.tutorial {
    padding-top: 8px;
    padding-bottom: 2px;
		background-color: $tutorial-color;
	}
	.interviste {
    padding-top: 8px;
    padding-bottom: 2px;
		background-color: $studi-color;
	}

	#back { float: right; }
	.back { margin-top: -100px; }
	.menu {
		font-size: 1.1em;
	}
	.mio-studio {
		color: $ricerca-color;
	}
	.gli-studi {
		color: $studi-color;
	}
}
// ---
.hp {

  color: $black;

  .row {
    padding: 0px !important;
  }
  .link {
    color: $black;
    a {
      color: $black;
      font-size: 1.1em;
    }
    a:hover {
      outline: 0;
      color: #3571ea !important;
    }
    padding-left: 30px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-image: url("../img/bullet-hp.svg");
  }
	.icona {
    width: 100px;
    float: left;
    margin-right: 10px;
  }
	.button {
		border: 0;
		text-align: left;
		line-height: 1.3rem;
    font-size: 1.0rem;
	}
  .pdf {
    text-align: center;
    border: 1px solid $primary-color;
    margin-top: 5px;
  }
  /*
	.riga {
		margin-top: 10px;
		margin-bottom: 20px;
		height: 2px;
	}
  */
	.colonna {
		/*
    min-height: 420px;
		background-color: #F7F7F7;
    */
		margin-bottom: 20px;
    /*padding: 10px;*/
	}
  .contenuto {
    padding-top: 20px;
    padding-bottom: 20px;
  }
	.titolo {
		font-size: 1.4em;
	}
	.descrizione {
    color: $black;
		font-size: 1.1em;
	}
  .decorazia {
    height: 170px;
    float: left;
  }

	.cerca {
    margin-bottom: 40px;
		border: 1px solid $ricerca-color;
		color: $ricerca-color;
    .sx {
      background-color: #e6f4ff;
    }
    .dx {
      margin-top: 25px;
    }
	}
	.studi {
    margin-bottom: 40px;
		border: 1px solid $studi-color;
		color: $studi-color;
    .sx {
      background-color: #fae7fa;
    }
    .dx {
      margin-top: 5px;
    }
	}
	.tutorial {
		border: 1px solid $tutorial-color;
		color: $tutorial-color;
    .sx {
      background-color: #ffeee6;
    }
    .dx {
      margin-top: 5px;
    }
		.visto {
			padding-left: 4px;
			border-left: 4px solid $tutorial-color;
		}
    .in-visione { font-weight: bold; }
    .button {
      width: 100%;
      /*line-height: 1rem;*/
      /*border-bottom: 1px solid $tutorial-color;*/
    }
    .last { border-bottom: 0px; }

    .separatore {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: -15px;
      border-top: 1px solid $tutorial-color;
    }
    .indice {
      margin-top: 10px;
      margin-left: 15px;
      font-weight: bold;
    }
	}
}

.interviste {
	.button {
		border: 0;
		text-align: left;
		line-height: 1.3rem;
    font-size: 1.0rem;
	}
  .box-autore {
    padding: 15px;
    margin-bottom: 20px;
    background-color: #e9e8e6;
    border: 1px solid #e0e0e2;
  }

	.studi {
    margin-bottom: 20px;
		border: 1px solid $studi-color;
		color: $studi-color;
    .box-label {
      background-color: #fae7fa;
      padding: 15px;
    }
    .separatore {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: -15px;
      border-top: 1px solid $studi-color;
    }
	}
}

// ---
.presenta {
	.icona {
    width: 50px;
    float: left;
    margin-right: 10px;
  }
	.riga {
		margin-top: 20px;
		//margin-bottom: 20px;
		height: 2px;
	}
	.colonna {
		min-height: 420px;
		margin-bottom: 20px;
		background-color: #F7F7F7;
	}
	.titolo {
		font-weight: bold;
		font-size: 1.3em;
	}
	.cerca {
    color: $ricerca-color;
	}
	.studi {
		color: $studi-color;
	}
	.tutorial {
		color: $tutorial-color;
	}
  .testo { color: black; }
}
// ---
.search-form {
  font-size: 0.9rem;
	.awesomplete { width: 100%; }
  .desc {
    margin-bottom: 5px;
  }
	.riga {
		background-color: $primary-color;
		margin-top: 10px;
		margin-bottom: 20px;
		height: 1px;
	}
}
// ---
.search-list {
	margin-bottom: 20px;
  a { color: $black; }
  #bookmarks {
    a { color: $white; }
  }
  .callout {
    border: 0px;
    background-color: #e6f4ff;
  }
	.header {
		padding: 10px;
		border-top: 1px solid $primary-color;
		border-bottom: 1px solid $primary-color;
		margin-bottom: 5px;
		font-weight: bold;
		/*.b-right { border-right: 1px solid $primary-color; }*/
	}
	.rows {
		padding: 10px;
    /*
		border-left: 1px solid $light-gray;
		border-right: 1px solid $light-gray;
    */
		border-bottom: 1px solid #e6f4ff;
		/*.b-right { border-right: 1px solid $light-gray; }*/
	}
}
// ---
.preferiti {
	.rows {
		.b-right { min-height: 100px; }
	}
	.nota-ricerca {
		font-size: 0.8rem;
		margin-left: -15px;
		margin-bottom: 5px;
	}
}
// ---
.record {
	padding: 10px;
	margin-top: 10px;
	background-color: $light-gray;
	#the_title { font-weight: bold; }
	.title { color: $primary-color; }
	.id {
		font-weight: bold;
		border: 0px;
		border-right: 1px solid $black;
	}
	.riga-blu {
		background-color: $primary-color;
		margin-top: 10px;
		margin-bottom: 10px;
		height: 1px;
	}
	.riga-grigia {
		background-color: $light-gray;
		margin-top: 5px;
		margin-bottom: 5px;
		height: 1px;
	}
}
// ---
.crediti {
	.titolo { font-size: 1.4rem; margin-bottom: 10px; }
	.copyright { font-size: 0.7rem; margin-top: 15px; }
	.filetto { background-color: $dark-gray; height: 1px; margin-top: 20px; margin-bottom: 20px; float: none; }
}
// ---
.tutorial {
	.intro { font-size: 0.96rem; text-align: justify;}
	.mappa {
		width: 800px;
		height: 500px;
	}
	.cerchio {
		width: 50px;
		height: 50px;
		line-height: 45px;
		text-align: center;
		position: relative;
		.numero {
			top: 2px;
			left: 17px;
			font-size: 1.9em;
			position: absolute;
		}
		.due-cifre {
			left: 7px;
		}
	}
	.set-testo {
		top: 0px;
		left: 55px;
		width: 250px;
		line-height: 22px;
		text-align: left;
		position: absolute;
		font-size: 0.9em;
	}
	.mappa-1 {
		background-image: url('../img/mappa-1.svg');
		background-repeat: no-repeat;
		background-size: 718px auto;
    background-position: 25px 50px;
    -ms-background-position-y: -5px;
		.cerchio-1 { top:15px; left:28px; .testo { top: 12px; } }
		.cerchio-2 { top: 85px; left: 88px; }
		.cerchio-3 { top: 125px; left: -15px; .testo { top: 12px; } }
		.cerchio-4 { top: 167px; left: 150px; .testo { top: 12px; } }
		.cerchio-5 { top: 60px; left: 395px; .testo { top: 12px; } }
		.cerchio-6 { top: -115px; left: 395px; }
		.cerchio-7 { top: -230px; left: 698px; }
		.cerchio-8 { top: -185px; left: 580px; }
		.cerchio-9 { top: -10px; left: 516px; .testo { top: 12px; } }
	}
	.mappa-2 {
		background-image: url('../img/mappa-2.svg');
		background-repeat: no-repeat;
    background-size: 718px auto;
    background-position: 25px 50px;
    -ms-background-position-y: -5px;
		.cerchio-1 { top:30px; left:-15px; }
		.cerchio-2 { top:230px; left:165px; .testo { left:-255px; text-align: right; } }
		.cerchio-3 { top:50px; left:363px; .testo { top: 12px; } }
		.cerchio-4 { top:210px; left:700px; .testo { left:-255px; text-align: right; } }
	}
	.mappa-3 {
		background-image: url('../img/mappa-3.svg');
		background-repeat: no-repeat;
    background-size: 718px auto;
    background-position: 25px 50px;
    -ms-background-position-y: -25px;
		.cerchio-1 { top:5px; left:-15px; }
		.cerchio-2 { top:70px; left:-15px; .testo { top: 12px; } }
		.cerchio-3 { top:170px; left:131px; .testo { top: 12px; } }
		.cerchio-4 { top:90px; left:348px; .testo { top: 12px; } }
		.cerchio-5 { top:-110px; left:253px; }
		.cerchio-6 { top:-185px; left:698px; }
		.cerchio-7 { top:-150px; left:451px; }
		.cerchio-8 { top:-110px; left:560px; .testo { top: 12px; } }
		.cerchio-9 { top:-66px; left:560px; }
	}
	.mappa-4 {
		background-image: url('../img/mappa-4.svg');
		background-repeat: no-repeat;
    background-size: 718px auto;
    background-position: 25px 50px;
    -ms-background-position-y: -100px;
		.cerchio-1 { top:52px; left:-15px; }
		.cerchio-2 { top:88px; left:700px; }
	}
}
// ---

.anima {
	border: 1px solid $tutorial-color;
}
.solo-testo {
		padding: 10px;
		min-height: 566px;
		border: 1px solid $tutorial-color;
		ul { margin-left: 1.0rem !important; }
}
.testo-anima {
	padding: 10px;
	min-height: 566px;
	border: 1px solid $tutorial-color;
	display: none;
	ul { margin-left: 1.0rem !important; }
	.tip {
		padding: 5px;
		font-size: 0.8em;
		margin-top: 30px;
		border: 1px solid $dark-gray;
		background-color: $light-gray;
	}
}
.titolo-anima {
	padding: 10px;
	text-transform: uppercase;
	border: 1px solid $tutorial-color;
	border-bottom: 0px;
}
.control-anima {
	padding: 10px;
	text-transform: uppercase;
	border: 1px solid $tutorial-color;
	border-top: 0px;
	margin-bottom: 10px;
}
//.testo-anima-wrap { display: none; }
#show_anima_slide { display: none; }

/*
.the-iframe {
	border: none;
	width: 100%;
	height: 648px;
	overflow: hidden;
}
*/

// ---
.alfabeto {
	width: 1166px;
	z-index: 666;
	position: fixed;
	margin-top: -16px;
	margin-bottom: 10px;
	margin-left: 0px !important;
	height: 60px;
	font-size: 1.5em;
	font-weight: bold;
	line-height: 60px;
	text-align: center;
	background-color: $light-gray;
	.separa { border-right: 1px solid $dark-gray; }
	.lettera { width: 10%; float: left; }
}
.glossario {
	margin-top: 70px;
}
// ---
.footer {
  /*
  position: fixed;
  display: table;
  bottom: -10px;
  */
  width: 100%;
  text-align: center;
  padding-top: 15px;
  background-color: $primary-color;

	a { color: $white; }
	a:hover { text-decoration: underline; }

  .testo {
    text-align: center;
    font-size: 0.8em;
    color: white;
  }
	.riga {
		margin-bottom: 5px;
		height: 8px;
    @include sfondo_con_gradiente_h;
	}
	.columns { text-align: center; }
	.icona { width: 40px; }
}

.riga {
  width: 100%;
	height: 8px;
	margin-top: 40px;
	margin-bottom: 40px;
  @include sfondo_con_gradiente_h;
}

// ---
svg {
  width: 50px;
	height: 50px;
  //transform: rotate(-90deg);
  background: $white;
  border-radius: 50%;
	border: 1px solid $black;
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
}
circle {
  fill: $white;
  stroke: $circle;
  stroke-width: 32;
	opacity: 0.3;
	//stroke-dasharray: 0 100;
}

@media only screen and (max-width: 1024px) {
	.alfabeto {
		width: 990px;
		font-size: 1.3em;
	}
	.fa { font-size: 2.5em; }

  .footer {
    font-size: 0.8em;
  	.icona { width: 35px; }
  }
  .top {
    .brand { margin-left: 3rem; }
    .utili { margin-right: -2rem; }
  }
  .subtop .testo {
    padding-right: 10px;
  }
  .hp {
    .cerca, .studi, .tutorial {
      border-left: 0px;
      border-right: 0px;
    }
  }
}

@media only screen and (max-width: 768px) {
	.alfabeto {
		width: 734px;
		font-size: 1.0em;
	}
  .subtop {
    .medium-5 {
      width: 100%;
    }
    .logo-home {
      padding-left: 250px;
      padding-right: 250px;
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .footer {
    font-size: 0.7rem;
    .icona { width: 30px; }
    .columns {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .testo-anima {
  	min-height: 480px;
  }
	.tutorial {
    .mappa-1 {
			.cerchio-7 { .testo { left:-255px; text-align: right; } }
		}
    .mappa-3 {
			.cerchio-6 { .testo { left:-255px; text-align: right; } }
		}
    .mappa-4 {
			.cerchio-2 { .testo { left:-255px; text-align: right; } }
		}
	}
}

@media only screen and (max-width: 640px) {

	body { font-size: 0.9em; }

  .privacy { font-size: 0.9rem; }

  .no-pad-left, .no-pad-right {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .search-list {
    .header { display: none; }
  }

	.abc { display: none; }

  .testo-anima { min-height: 380px; }

	.fa { font-size: 1.5em; }

	.page-header {
		#back { float: none; }
		.back { margin-top: 10px; }
		.button { font-size: 0.6rem; }
		.menu { font-size: 0.85em; line-height: 1.2em; }
		.titolo { font-size: 1.05em; line-height: 1.2em; }
	}

  .hp {
    .colonna {
  		min-height: 20px;
  		margin-bottom: 10px;
  	}
    .icona { width: 45px; }
  }
  .crediti {
  	.titolo { font-size: 1.1rem; margin-bottom: 10px; }
  }

  .footer {
    position: static;
  }

	.footer .columns { text-align: left !important; }

  .riga-footer-mobile {
		height: 3px;
    @include sfondo_con_gradiente_h;
	}

	.tutorial {
    .mappa {
      width: 350px;
      position: relative;
      .sfondo-mobile {
        width: 3px;
        left: 41px;
        position: absolute;
        top: 80px;
        @include sfondo_con_gradiente_v;
      }
    }
		.mappa-1 {
			height: 700px;
			background-image: none;
			background-repeat: no-repeat;
			.cerchio-1 { top:30px; left:1px; .testo { top: 12px; } }
			.cerchio-2 { top:50px;  left:1px; }
			.cerchio-3 { top:70px;  left:1px; }
			.cerchio-4 { top:90px;  left:1px; }
			.cerchio-5 { top:110px; left:1px; }
			.cerchio-6 { top:130px; left:1px; }
			.cerchio-7 { top:150px; left:1px; }
			.cerchio-8 { top:170px; left:1px; }
			.cerchio-9 { top:190px; left:1px; }
      .sfondo-mobile { height: 550px; }

		}
		.mappa-2 {
			height: 420px;
      background-image: none;
			background-repeat: no-repeat;
			.cerchio-1 { top:30px; left:1px; }
			.cerchio-2 { top:50px; left:1px; .testo { left:55px; text-align: left; } }
			.cerchio-3 { top:70px; left:1px; .testo { top: 12px; } }
			.cerchio-4 { top:90px; left:1px; .testo { left:55px; text-align: left; } }
      .sfondo-mobile { height: 200px; }
		}
		.mappa-3 {
      height:770px;
      background-image: none;
			background-repeat: no-repeat;
			.cerchio-1 { top:30px; left:1px; }
			.cerchio-2 { top:50px; left:1px; }
			.cerchio-3 { top:70px; left:1px; .testo { top: 12px; } }
			.cerchio-4 { top:90px; left:1px; .testo { top: 12px; } }
			.cerchio-5 { top:110px; left:1px; }
			.cerchio-6 { top:130px; left:1px; }
			.cerchio-7 { top:150px; left:1px; }
			.cerchio-8 { top:170px; left:1px; .testo { top: 12px; } }
			.cerchio-9 { top:190px; left:1px; }
      .sfondo-mobile { height: 550px; }
		}
		.mappa-4 {
      height: 300px;
      background-image: none;
			background-repeat: no-repeat;
			.cerchio-1 { top:30px; left:1px; }
			.cerchio-2 { top:50px; left:1px; }
      .sfondo-mobile { height: 50px; }
		}
	}

  .subtop {
    .medium-5 {
      width: 100%;
    }
    .logo-home {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

}

@media only screen and (max-width: 640px) {
  .top {
    .brand { display: none; }
  }
  .arrow-video { display: none; }
}

.bs-glyphicons-list {list-style: outside none none;padding-left: 0;}
.bs-glyphicons-list span {padding-right:10px;color:#d0d0d0;}

//awesomplete.css

[hidden] { display: none; }

.visually-hidden {
	position: absolute;
	clip: rect(0, 0, 0, 0);
}

div.awesomplete {
	display: inline-block;
	position: relative;
}

div.awesomplete > input {
	display: block;
}

div.awesomplete > ul {
	position: absolute;
	left: 0;
	z-index: 1;
	min-width: 100%;
	box-sizing: border-box;
	list-style: none;
	padding: 0;
	border-radius: .3em;
	margin: .2em 0 0;
	background: hsla(0,0%,100%,.9);
	background: linear-gradient(to bottom right, white, hsla(0,0%,100%,.8));
	border: 1px solid rgba(0,0,0,.3);
	box-shadow: .05em .2em .6em rgba(0,0,0,.2);
	text-shadow: none;
}

div.awesomplete > ul[hidden],
div.awesomplete > ul:empty {
	display: none;
}

@supports (transform: scale(0)) {
	div.awesomplete > ul {
		transition: .3s cubic-bezier(.4,.2,.5,1.4);
		transform-origin: 1.43em -.43em;
	}

	div.awesomplete > ul[hidden],
	div.awesomplete > ul:empty {
		opacity: 0;
		transform: scale(0);
		display: block;
		transition-timing-function: ease;
	}
}

/* Pointer */
div.awesomplete > ul:before {
	content: "";
	position: absolute;
	top: -.43em;
	left: 1em;
	width: 0; height: 0;
	padding: .4em;
	background: white;
	border: inherit;
	border-right: 0;
	border-bottom: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

div.awesomplete > ul > li {
	position: relative;
	padding: .2em .5em;
	cursor: pointer;
}

div.awesomplete > ul > li:hover {
	background: hsl(200, 40%, 80%);
	color: black;
}

div.awesomplete > ul > li[aria-selected="true"] {
	background: hsl(205, 40%, 40%);
	color: white;
}

div.awesomplete mark {
	background: hsl(65, 100%, 50%);
}

div.awesomplete li:hover mark {
	background: hsl(68, 100%, 41%);
}

div.awesomplete li[aria-selected="true"] mark {
	background: hsl(86, 100%, 21%);
	color: inherit;
}

/* TOP MENU - 07 2024 */

.navbar {
  .voci {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: left;
  }
  li {
    position: relative;
    line-height: 30px;
  }
  li:hover {
    background-color: $primary-color;
  }
  .no-li:hover {
    background-color: transparent;
  }
  a {
    text-decoration: none;
    color: white;
    padding: 10px 15px;
    display: block;
  }
  a:hover {
  }
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: $primary-color;
    min-width: 200px;
    margin-left: 0px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    li {
      line-height: 20px;
    }
    a {
      color: white;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    a:hover {
        background-color: #575757;
    }
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* Mobile Styles */
.menu-toggle {
  float: right---------------;
  display: none;
  cursor: pointer;
  svg {
    border: 0;
    border-radius: 0;
    color: white;
    background-color: transparent;
    transform: none;
    width: 30px;
  }
}
.navbar-mobile {
  display: none;
  margin-top: 15px;
  font-size: 1rem;
  height: 500px;
  overflow-y: scroll;
  a {
    color: white;
    display: block;
    font-weight: normal;
    text-decoration: none;
  }
  dt {
    margin-bottom: 15px;
    padding-left: 25px;
    background-image: url("../img/bullet-1.svg");
    background-repeat: no-repeat;
  }
  dd {
    margin-left: 20px;
    margin-bottom: 15px;
    padding-left: 25px;
    background-image: url("../img/bullet-2.svg");
    background-repeat: no-repeat;
  }
}

.logo-accmed {
  width: 90px;
  margin-top: 14px;
}
.logo-mytrial {
  width: 50px;
  margin-top: 12px;
}
.logo-home {
  padding-left: 70px;
  padding-right: 70px;
  border-right: 1px solid white;
}

.img-responsive {
  width: 100%;
}

.titolo-studio {
  margin-bottom: 20px;
}

.top-mobile {
  background: $primary-color;
}

.logo-accmed-mobile { display: none; }

.bullet-1 {
  list-style-image: url('../img/bullet-1.svg');
}
.bullet-2 {
  list-style-image: url('../img/bullet-2.svg');
}

.sponsor {
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 40px;
}

.button-on-callout { color: $white !important; }

.paginazione {
  font-size: 1.1rem;
  margin-top: 20px;
  padding-bottom: 15px;
}

.testo-modal-help {
  font-size: 1rem;
  margin-top: 20px;
}

@media (max-width: 768px) {

  .navbar .voci {
    flex-direction: column;
    display: none;
  }

  .navbar .voci.active {
    display: flex;
  }

  .navbar-mobile.active {
    display: block;
  }

  .menu-toggle {
    display: flex;
  }

  .subtop {
    top: 50px;
    .testo {
      display: none;
    }
  }

  .logo-home {
    border-right: 0;
    text-align: center;
  }

  .logo-accmed-mobile {
    display: block;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 20px;
  }

  .sponsor {
    font-size: 0.9rem;
  }

  .box-autore {
    display: none;
  }
}
